import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Explore Unlimited
			</title>
			<meta name={"description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:title"} content={"Explore Unlimited"} />
			<meta property={"og:description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Text
			font="normal 600 36px/1.3 --fontFamily-sansTrebuchet"
			color="--darkL1"
			lg-margin="0px 0px 20px 0px"
			sm-margin="0px 0px 10px 0px"
			margin="25px auto 25px auto"
			width="50%"
			md-width="100%"
			md-margin="0 0 20px 0"
			text-align="center"
		>
			Наші першокласні послуги
		</Text>
		<Section
			padding="120px 0 120px 0"
			lg-margin="0 0 0 0"
			lg-padding="80px 0 80px 0"
			background="--color-lightD1"
			quarkly-title="Price-20"
		>
			<Box padding="0px 120px 0px 120px" lg-flex-direction="column" lg-padding="0px 0 0px 0">
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Екскурсії
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Експертні гіди. Наші досвідчені гіди забезпечать безпечну та захопливу пригоду.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Індивідуальні маршрути: адаптуйте свій досвід відповідно до вашого рівня пригод та інтересів.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Самостійні поїздки
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Першокласні навігаційні інструменти. Подорожуйте впевнено завдяки нашим сучасним системам GPS.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Свобода дослідження: досліджуйте стежки у зручному для вас темпі та відповідно до свого графіку.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Навчання техніці безпеки
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Комплексні брифінги: дізнайтеся все, що вам потрібно знати про безпеку та експлуатацію квадроциклів.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Комплексные брифинги: узнайте все, что вам нужно знать о безопасности и эксплуатации квадроциклов.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Хостинг заходів
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Особливі випадки: ідеально підходить для групових заходів, від корпоративного відпочинку до сімейних зустрічей.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Пакети "все включено": насолодіться повністю організованим заходом із харчуванням та обладнанням.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					justify-content="space-between"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="25%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Прокат спорядження
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="50%"
						margin="0px 0px 0px 10%"
						padding="0px 50px 0px 0px"
						md-margin="0 0 25px 0"
						md-width="100%"
					>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Високоякісне спорядження: від шоломів до захисного спорядження - ми надамо вам усе необхідне.
						</Text>
						<Text margin="0px 0px 7px 0px" font="normal 400 20px/1.5 --fontFamily-sansHelvetica" color="--darkL1">
							- Різноманітність опцій: спорядження підходить для будь-яких погодних умов і типів їзди.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});